import React, { useState } from 'react';
import Header from '../components/Header';
import lawrence from '../assets/images/lawrence-updated.png'
import presentation from '../assets/images/lawrence_presentation.jpeg'
import '../css/project-page.css'
import '../App.css';

function Lawrence() {
    const [enlargedImage, setEnlargedImage] = useState(null);
    const handleImageClick = (imageSrc) => {
        setEnlargedImage(imageSrc);
        document.body.classList.add('no-scroll');

    };

    const closeEnlargedImage = () => {
        setEnlargedImage(null);
        document.body.classList.remove('no-scroll');
    };


    return (

        <div>
            {enlargedImage && (
                <div className="enlarged-image-container" onClick={closeEnlargedImage}>
                    <div className="close-button" onClick={(e) => {
                        e.stopPropagation();
                        closeEnlargedImage();
                    }}>×</div>
                    <img src={enlargedImage} alt="Enlarged" />
                </div>
            )}

            <div className="outer-container">
                <Header />
                <div className='container'>
                    <div className='page-title'>
                        Lawrence
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}>
                        <div className="flex-container">
                            <div className="image-container" onClick={() => handleImageClick(lawrence)}>
                                <img src={lawrence} alt='Lawrence' />
                            </div>
                            <div className="text-container">
                                <p>
                                    Lawrence is an advanced question-answering bot I developed for my capstone project in the Lifecycle of AI Solutions course at the University of Iceland. By integrating OpenAI's GPT models with an extensive database of legislative texts, Lawrence is adept at addressing complex legal inquiries across multiple areas of law. This innovation stands out in its potential to democratize legal support, significantly reducing costs and enhancing access to legal assistance.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div style={{ height: '60px' }}></div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}>
                        <div className="flex-container">
                            <div className="image-container" onClick={() => handleImageClick(presentation)}>
                                <img src={presentation} alt='Presenting Lawrence' />
                            </div>
                            <div className="text-container">
                                <p>
                                    At the Lawyer Society of Iceland's luncheon focused on AI in the legal field, I had the privilege of presenting Lawrence. The talk illuminated how the bot serves as a practical tool for legal professionals, contributing to more efficient research and providing accessible legal guidance. The ensuing discussion with the attendees highlighted the practical benefits of AI applications like Lawrence in enhancing the day-to-day functions of legal practitioners.                        </p>
                            </div>
                        </div>
                    </div>
                    <div style={{ height: '60px' }}></div>
                </div>
            </div>
        </div>
    );
}

export default Lawrence;