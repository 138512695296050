import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import '../css/projects.css';
import '../css/colors.css';
import lawrence from '../assets/images/lawrence-updated.png';
import horseDay from '../assets/images/horseday-new.png';

function Projects() {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: true,
        });
    }, []);

    const projects = [
        {
            key: 1,
            title: 'HorseDay',
            imageUrl: horseDay,
            link: 'horseday',
        },
        {
            key: 2,
            title: 'Lawrence',
            imageUrl: lawrence,
            link: 'lawrence',
        },
    ];

    return (
        <div id="projects" className="projects-container">
            <div className='content-container' >
                {projects.map((project) => (
                    <div data-aos="fade-up" key={project.key}>
                        <div className="project">
                            <Link to={project.link} className="project-link">
                                {(
                                    <img src={project.imageUrl} alt={project.title} className="project-image" />
                                )}
                                <div className="project-title">{project.title}</div>
                            </Link>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );

}

export default Projects;
