import React, { useEffect } from 'react';

import AOS from 'aos';
import '../css/about.css';
import me from '../assets/images/profile-photo.jpeg';

function About() {
    useEffect(() => {
        AOS.init({
            duration: 700,
            once: true, 
        });
    }, []);

    return (
        <div className='about-container'>
            <div id='about' className='about-section'>
                <div className="profile-picture" data-aos="fade-up">
                    <img src={me} alt="Bjarki" />
                </div>
                <div className='about-right'>
                    <div className='about-text-container' >
                        <span>Hello! I'm Bjarki Vidarsson, a designer and developer bound by <span className='highlighted-word'>ceaseless</span> curiosity. I combine creativity with technical expertise to create digital solutions that are both engaging and effective. I focus on tackling complex challenges and transforming them into intuitive and elegant solutions. I strive for continuous improvement with every project, seeing each new challenge as an opportunity to innovate and excel.</span>
                    </div>
                    <a className="cta-button" href="mailto:bjarki.vidars@gmail.com">Get in Touch</a>
                </div>
            </div>
        </div>
    )
}

export default About;