import React, { useState, useRef } from 'react';
import '../css/header.css';
import '../App.css';

function Logo() {
    const [firstName, setFirstName] = useState('B');
    const [lastName, setLastName] = useState('V');
    const fullFirstName = 'Bjarki';
    const fullLastName = ' Vidarsson';
    const typingDelay = 40;
    const typingIntervalRef = useRef(null);

    const typeNames = () => {
        let indexFirst = firstName.length;
        let indexLast = lastName.length;
        return setInterval(() => {
            let updated = false;

            if (indexFirst < fullFirstName.length ) {
                setFirstName(fullFirstName.slice(0, indexFirst + 1));
                indexFirst++;
                updated = true;
            }

            if (indexLast < fullLastName.length) {
                setLastName(fullLastName.slice(0, indexLast + 2));
                indexLast++;
                updated = true;
            }

            if (!updated) {
                clearInterval(typingIntervalRef.current);
            }
        }, typingDelay);
    };

    const eraseNames = () => {
        let index = Math.max(firstName.length, lastName.length);
        return setInterval(() => {
            if (index > 1) {
                if (index - 1  <= firstName.length && index > 2) {
                    setFirstName(fullFirstName.slice(0, index - 2));
                }
                if (index <= lastName.length) {
                    // Correctly handle the space before the last name
                    const lastNameSlice = index > 2 ? ' ' + fullLastName.slice(1, index - 1) : 'V';
                    setLastName(lastNameSlice);
                }
            } else {
                clearInterval(typingIntervalRef.current);
                setFirstName('B');
                setLastName('V');
            }
    
            index--;
        }, typingDelay);
    };
    


    const handleMouseEnter = () => {
        clearInterval(typingIntervalRef.current);
        typingIntervalRef.current = typeNames();
    };

    const handleMouseLeave = () => {
        clearInterval(typingIntervalRef.current);
        typingIntervalRef.current = eraseNames();
    };

    return (
        <div className="logo" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <span>{firstName}</span>
            <span>{lastName}</span>
        </div>
    );
}

export default Logo;
