import React from 'react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';


import Header from './components/Header';
import Main from './components/Main';
import Projects from './components/Projects';
import About from './components/About';
import Landing from './components/Landing';
import Landing2 from './components/Landing-2';
import './App.css';



function HomePage() {
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (location.state?.scrollTo !== '') {
            console.log('dping this');
            const projectsElement = document.getElementById(location.state?.scrollTo);
            if (projectsElement) {
                projectsElement.scrollIntoView();
                navigate(location.pathname, { state: { ...location.state, scrollTo: '' }, replace: true });

            }
        }
    }, [location]);

    return (
        // <div class="fixed inset-0 -z-10 transform-gpu animate-move-bg opacity-100 transition-opacity duration-3000 main-gradient bg-main-gradient">
        <div >
            <Header />
            <Landing2/>
            <div className='background'>
                <div className='container'>
                    <Projects />
                    <About />
                </div>
            </div>
            
        </div>
    );
}

export default HomePage;
