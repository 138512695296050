import React, { useEffect } from 'react';
import { BrowserRouter as Router, useLocation, Route, Routes } from 'react-router-dom';

import HomePage from './HomePage';
import Lawrence from './pages/Lawrence';
import HorseDay from './pages/HorseDay';



function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/lawrence" element={<Lawrence />} />
      <Route path="/horseday" element={<HorseDay />} />
    </Routes>
  );
}

export default App;
