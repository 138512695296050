import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../css/landing.css';
import BV from '../assets/videos/BV-2.webm';

const Landing2 = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const videoSectionRef = useRef(null);
    const [opacity, setOpacity] = useState(0);
    const [currentWord, setCurrentWord] = useState('');
    const [animate, setAnimate] = useState(false);

    const words = ['Designer', 'Developer'];
    let isDeleting = false;
    let wordIndex = 0;
    let text = '';
    const typingSpeed = 150;
    const deletingSpeed = 50;
    const pauseTime = 1000;

    const typeEffect = () => {
        const fullWord = words[wordIndex % words.length];
        if (!isDeleting) {
            text = fullWord.substring(0, text.length + 1);
        } else {
            text = fullWord.substring(0, text.length - 1);
        }
        setCurrentWord(text);

        let delta = isDeleting ? deletingSpeed : typingSpeed;
        if (!isDeleting && text === fullWord) {
            isDeleting = true;
            delta = pauseTime;
        } else if (isDeleting && text === 'De') {
            isDeleting = false;
            wordIndex++;
            delta = 200;
        }

        setTimeout(typeEffect, delta);
    };

    useEffect(() => {
        const timer = setTimeout(typeEffect, 1000);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        setTimeout(() => setOpacity(1), 2000);

        const handleScrollOpacity = () => {
            const newOpacity = window.scrollY < window.innerHeight * 0.85 ? 1 : 0;
            setOpacity(newOpacity);
        };

        window.addEventListener('scroll', handleScrollOpacity);
        return () => window.removeEventListener('scroll', handleScrollOpacity);
    }, []);

    useEffect(() => {
        const handleScrollParallax = () => {
            const scrolled = window.scrollY;
            const parallaxSpeed = 0.25;

            if (videoSectionRef.current) {
                const translateY = scrolled * parallaxSpeed;
                videoSectionRef.current.style.transform = `translate(-50%, calc(-50% + ${translateY}px))`;
            }
        };

        window.addEventListener('scroll', handleScrollParallax);
        return () => window.removeEventListener('scroll', handleScrollParallax);
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => setAnimate(true), 5000);

        const handleScrollAnimate = () => {
            clearTimeout(timer);
            setAnimate(true);
        };

        window.addEventListener('scroll', handleScrollAnimate);
        return () => {
            clearTimeout(timer);
            window.removeEventListener('scroll', handleScrollAnimate);
        };
    }, []);

    const scrollToSectionHere = (sectionId) => {
        const sectionElement = document.getElementById(sectionId);
        if (sectionElement) {
            sectionElement.scrollIntoView({ behavior: 'smooth' });
            navigate(location.pathname, { state: { ...location.state, scrollTo: '' }, replace: true });
        }
    };

    return (
        <div>
            <div className="landing-page">
                <div className='upper'>
                    <div>Bjarki Vidarsson</div>
                    <div className='blue-line'>
                        <div>{currentWord}</div>
                    </div>
                </div>
                <div className="video-section" ref={videoSectionRef}>
                    <video
                        loop
                        muted
                        playsInline
                        autoPlay
                    >
                        <source src={BV} type="video/webm" />
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div className="links-section" style={{
                    opacity: opacity,
                    pointerEvents: opacity === 0 ? 'none' : 'auto'
                }}>
                    <nav className={`nav-links-landing ${animate ? 'start-animation' : ''}`}>
                        <li className="navLink-landing" onClick={() => scrollToSectionHere('projects')}>
                            Projects
                        </li>
                        <li className="navLink-landing" onClick={() => scrollToSectionHere('about')}>
                            About
                        </li>
                    </nav>
                </div>

            </div>
        </div>

    );
};

export default Landing2;