import React, { useState, useRef, useEffect } from 'react';
import { debounce } from 'lodash';
import Header from '../components/Header';

import '../css/project-page.css'
import '../App.css';

import horseProfile from '../assets/videos/horse-profile.webm';
import map from '../assets/videos/analysis-screen-new.webm';
import monthToWeek from '../assets/videos/weekly-wb-new.webm';
import monthly from '../assets/videos/monthly-wb-new.webm';
import premium from '../assets/videos/premium-screen.webm';
import registration from '../assets/videos/register-new.webm';
import track from '../assets/videos/track.webm';
import feed from '../assets/videos/feed-new.webm';



function HorseDay() {
    const [hoveredVideo, setHoveredVideo] = useState(null);
    const [prevTranslateY, setPrevTranslateY] = useState(0);
    const [initialTranslateY, setInitialTranslateY] = useState(0);
    const videoSources = [horseProfile, feed, monthToWeek, registration, monthly, premium, track, map];
    const videoRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];
    const offset = 20;
    const [videosLoaded, setVideosLoaded] = useState(false);

    useEffect(() => {
        if (!videosLoaded) return;
        const debouncedUpdateVideoPosition = debounce(() => {
            if (hoveredVideo === null) return;
            const videoElement = videoRefs[hoveredVideo]?.current;
            if (videoElement) {
                const videoRect = videoElement.getBoundingClientRect();
                const viewportHeight = window.innerHeight;

                const bottomOverlap = videoRect.bottom - viewportHeight;
                const topOverlap = videoRect.top;

                let translateY = 0;
                if (initialTranslateY !== 0) {
                    setPrevTranslateY(initialTranslateY);
                    setInitialTranslateY(0);
                }
                if (bottomOverlap > 0 || topOverlap < 0) {
                    if (bottomOverlap > 0) {
                        translateY = -(bottomOverlap + offset);
                    } else {
                        translateY = -(topOverlap - offset);
                    }
                    translateY += prevTranslateY;
                    videoElement.style.transform = `translateY(${translateY}px)`;
                    setPrevTranslateY(translateY);
                }
                else if (topOverlap > 0 && prevTranslateY > 0) {
                    let min = Math.min(topOverlap, prevTranslateY) - offset;
                    translateY = prevTranslateY - min;
                    videoElement.style.transform = `translateY(${translateY}px)`;
                    setPrevTranslateY(translateY);
                } else if (bottomOverlap < 0 && prevTranslateY < 0) {
                    let min = Math.min(-bottomOverlap, -prevTranslateY) + offset;
                    translateY = prevTranslateY + min;
                    videoElement.style.transform = `translateY(${translateY}px)`;
                    setPrevTranslateY(translateY);
                }
            }
        }, 50);

        if (hoveredVideo !== null) {
            window.addEventListener('scroll', debouncedUpdateVideoPosition, { passive: true });
        }

        return () => {
            window.removeEventListener('scroll', debouncedUpdateVideoPosition);
            debouncedUpdateVideoPosition.cancel(); // Cancel any pending debounced calls
        };
    }, [hoveredVideo, prevTranslateY, initialTranslateY, videosLoaded]);

    useEffect(() => {
        videoRefs.forEach((ref, idx) => {
            if (idx !== hoveredVideo && ref.current) {
                ref.current.style.transform = 'translateY(0)';
            }
        });
        setPrevTranslateY(0);
    }, [hoveredVideo]);

    const handleMouseEnter = (index) => {
        if (!videosLoaded) return;
        setHoveredVideo(index);
        videoRefs.forEach((ref, idx) => {
            if (idx !== index && ref.current && !ref.current.paused) {

                ref.current.pause();
            }
        });

        const videoElement = videoRefs[index].current;
        if (videoElement) {
            const videoRect = videoElement.getBoundingClientRect();
            const viewportHeight = window.innerHeight;

            let translateY = 0;
            const bottomOverlap = videoRect.bottom - viewportHeight;
            const topOverlap = videoRect.top;

            if (bottomOverlap > 0) {
                translateY = -bottomOverlap;
            } else if (topOverlap < 0) {
                translateY = -topOverlap;
            }
            setInitialTranslateY(translateY);
            videoElement.style.transform = `translateY(${translateY}px)`;
        }
    };

    const handleMouseLeave = (index) => {
        if (!videosLoaded) return;
        setHoveredVideo(null);
        videoRefs.forEach((ref) => {
            if (ref.current && ref.current.paused) {
                ref.current.play().catch(e => console.error("Error playing video: ", e));
                ref.current.style.transform = 'translateY(0)';
            }
        });
    };

    useEffect(() => {
        let loadedVideos = 0;
        videoRefs.forEach(ref => {
            const video = ref.current;
            if (video) {
                const handleVideoLoad = () => {
                    loadedVideos++;
                    if (loadedVideos === videoSources.length) {
                        console.log('loaded');
                        setVideosLoaded(true);
                    }
                };
                video.addEventListener('loadeddata', handleVideoLoad);
                return () => video.removeEventListener('loadeddata', handleVideoLoad);
            }
        });
    }, [videoRefs, videoSources.length]);

    return (
        <div className={`overlay ${hoveredVideo !== null ? 'active' : ''}`}>
            <div className="outer-container">
                <Header />
                <div className='container'>
                    <div className='page-title'>
                        HorseDay
                    </div>

                    <div className='video-outer'>
                        <div className='video-section-page-all'>
                            {videoSources.slice(0, 2).map((src, index) => {
                                const thisIndex = index;
                                return (
                                    <div key={thisIndex} className={`video-comp ${hoveredVideo === thisIndex ? 'enlarged' : ''}`}
                                        onMouseEnter={() => handleMouseEnter(thisIndex)}
                                        onMouseLeave={handleMouseLeave}>
                                        <video ref={videoRefs[thisIndex]} loop muted playsInline autoPlay>
                                            <source src={src} type="video/webm" />
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                );
                            })}
                        </div>
                        <div className='video-section-page-all'>
                            {videoSources.slice(2, 4).map((src, index) => {
                                const thisIndex = index + 2;
                                return (
                                    <div key={thisIndex} className={`video-comp ${hoveredVideo === thisIndex ? 'enlarged' : ''}`}
                                        onMouseEnter={() => handleMouseEnter(thisIndex)}
                                        onMouseLeave={handleMouseLeave}>
                                        <video ref={videoRefs[thisIndex]} loop muted playsInline autoPlay>
                                            <source src={src} type="video/webm" />
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div style={{ height: '30px' }}></div>
                    <div className='video-outer'>
                        <div className='video-section-page-all'>
                            {videoSources.slice(4, 6).map((src, index) => {
                                const thisIndex = index + 4;
                                return (
                                    <div key={thisIndex} className={`video-comp ${hoveredVideo === thisIndex ? 'enlarged' : ''}`}
                                        onMouseEnter={() => handleMouseEnter(thisIndex)}
                                        onMouseLeave={handleMouseLeave}>
                                        <video ref={videoRefs[thisIndex]} loop muted playsInline autoPlay>
                                            <source src={src} type="video/webm" />
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                );
                            })}
                        </div>
                        <div className='video-section-page-all'>
                            {videoSources.slice(6, 8).map((src, index) => {
                                const thisIndex = index + 6;
                                return (
                                    <div key={thisIndex} className={`video-comp ${hoveredVideo === thisIndex ? 'enlarged' : ''}`}
                                        onMouseEnter={() => handleMouseEnter(thisIndex)}
                                        onMouseLeave={handleMouseLeave}>
                                        <video ref={videoRefs[thisIndex]} loop muted playsInline autoPlay>
                                            <source src={src} type="video/webm" />
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div style={{ height: '20px' }}></div>
                    <div className="text-container">
                        <p>
                            HorseDay is an Icelandic startup aiming to be a one-stop app for all horse owners, trainers, and enthusiasts. It is meant for both browsing in studbooks and keeping track of all your horse's activities.
                            I originally joined HorseDay as a software developer but have since evolved into a UI/UX Designer & Engineer, taking on a much broader role and being actively involved in every aspect of the development cycle, from idea to design to implementation.
                        </p>
                        <p>
                            In taking the initiative to reimagine HorseDay's interface, I transformed the app's components from static elements to dynamic, engaging, and natural interactive experiences. The focus of this redesign extended far beyond aesthetics. It was about understanding and reshaping the user's interaction with our app. I introduced animations and interactive elements that brought a sense of fluidity and intuitiveness to the user experience. Each component was carefully crafted to not only look appealing but to feel alive and responsive to the user's touch, making their journey through the app both fun and seamless.
                        </p>
                        <p>
                            I spearheaded the introduction of the Activity Hub, transforming the bottom navigation bar into a dynamic component. This revamped bar serves dual purposes: facilitating tab navigation and morphing into a tracking overview panel or a registration form as needed. This innovation simplifies the tracking and registration processes by adaptively altering the navigation bar's state, enabling seamless user interactions within the same screen.
                        </p>
                        <p>

                            In parallel with these updates, I established stringent guidelines for interactive components in the app, effectively creating a cohesive design system. Previously, the app had a scattered array of buttons in various shapes, sizes, and colors. To address this, I streamlined the interface by introducing two distinct button styles: icon buttons and text buttons. The latter are further categorized into primary and secondary types, unifying the visual language of the app. Additionally, I developed standardized screen containers to ensure uniformity across all screens, particularly in terms of the refresh component and overall appearance. This approach not only enhanced the app's aesthetics but also reinforced a consistent user experience aligned with modern design principles.
                        </p>
                    </div>
                    <div style={{ height: '60px' }}></div>

                </div>
            </div>
        </div>
    );
}

export default HorseDay;
