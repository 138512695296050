import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import '../css/header.css';
import '../App.css';
import Logo from './Logo';


const Header = () => {
    const [opacity, setOpacity] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();
    const alwaysVisiblePages = ['/horseday', '/lawrence'];
    const isAlwaysVisiblePage = alwaysVisiblePages.includes(location.pathname);
    const [firstName, setFirstName] = useState('B');
    const [lastName, setLastName] = useState('V');
    const fullNameFirst = 'Bjarki';
    const fullNameLast = ' Vidarsson';
    const [activeSection, setActiveSection] = useState('');
    const projectsRef = useRef(null);
    const aboutRef = useRef(null);
    const [underlineStyle, setUnderlineStyle] = useState({});
    const isRoot = window.location.pathname === '/';

    const getActiveLinkRef = (section) => {
        if (section === 'projects') return projectsRef;
        if (section === 'about') return aboutRef;
        return null;
    };

    useEffect(() => {
        if (!isRoot) return;

        const handleResize = () => {
            const activeRef = getActiveLinkRef(activeSection);
            if (activeRef && activeRef.current) {
                const { left, width } = activeRef.current.getBoundingClientRect();
                setUnderlineStyle({
                    left: left + 'px',
                    width: width + 'px',
                });
            }
        };

        const handleScroll = () => {
            const viewportHeight = window.innerHeight;
            const middleOfScreen = viewportHeight / 2;

            const projectsElement = document.getElementById('projects');
            const aboutElement = document.getElementById('about');

            const projectsRect = projectsElement.getBoundingClientRect();
            const aboutRect = aboutElement.getBoundingClientRect();

            const distanceToProjectsBottom = Math.abs(middleOfScreen - projectsRect.bottom);
            const distanceToAboutTop = Math.abs(middleOfScreen - aboutRect.top);

            if (distanceToProjectsBottom < distanceToAboutTop) {
                setActiveSection('projects');
            } else {
                setActiveSection('about');
            }
        };

        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleScroll);

        handleResize();
        handleScroll();

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleScroll);
        };
    }, [activeSection, isRoot]);


    useEffect(() => {
        let firstInterval, lastInterval;

        if (firstName.length < fullNameFirst.length) {
            firstInterval = setInterval(() => {
                setFirstName(fullNameFirst.slice(0, firstName.length + 1));
            }, 100);
        }

        if (lastName.length < fullNameLast.length) {
            lastInterval = setInterval(() => {
                setLastName(fullNameLast.slice(0, lastName.length + 1));
            }, 100);
        }

        return () => {
            clearInterval(firstInterval);
            clearInterval(lastInterval);
        };
    }, [firstName, lastName]);


    useEffect(() => {
        const handleScroll = () => {
            const viewportHeight = window.innerHeight;
            const scrollTop = window.scrollY;
            const threshold = viewportHeight * 0.85;
            const newOpacity = scrollTop > threshold || isAlwaysVisiblePage ? 1 : 0;
            setOpacity(newOpacity);
        };

        window.addEventListener('scroll', handleScroll);

        setOpacity(isAlwaysVisiblePage ? 1 : 0);

        return () => window.removeEventListener('scroll', handleScroll);
    }, [location.pathname]);

    const handleLogoClick = () => {
        if (location.pathname !== '/') {
            navigate('/', { state: { hasTyped: true } });
        } else {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    const handleSectionClick = (e, pathname) => {
        e.preventDefault();

        if (window.location.pathname !== '/') {
            navigate('/', { state: { scrollTo: pathname, hasTyped: true } });
        } else {
            document.getElementById(pathname).scrollIntoView({ behavior: 'smooth' });
        }
    };



    const headerClass = opacity === 0 ? 'header header-hidden' : 'header';

    return (
        <header className={headerClass}>
            <div className='header-container'>
                <div onClick={handleLogoClick}>
                    <Logo />
                </div>
                <nav>
                    <ul className="nav-links">
                        <li ref={projectsRef}>
                            <Link className={`navLink ${activeSection === 'projects' ? 'active' : ''}`} to='/' onClick={(e) => handleSectionClick(e, 'projects')}>Projects</Link>
                        </li>
                        <li ref={aboutRef}>
                            <Link className={`navLink ${activeSection === 'about' ? 'active' : ''}`} to='/' onClick={(e) => handleSectionClick(e, 'about')}>About</Link>
                        </li>
                    </ul>
                    {isRoot && <div className="sharedUnderline" style={underlineStyle}></div>}
                </nav>
            </div>
        </header>
    );
};

export default Header;
